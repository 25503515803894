// GameList.tsx
import React, { useEffect, useState } from 'react';
import { collection, query, onSnapshot, doc, deleteDoc, updateDoc } from "firebase/firestore";
import { db } from '../firebase';
import { Card, CardMedia, CardContent, Typography, Container, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddGameForm from '../functions/AddGameForm';
import '../styles/GameList.css';

interface Game {
  id: string;
  title: string;
  description: string;
  genre: string;
  multiplayerInfo: string;
  imageURL: string;
}

const GameList: React.FC = () => {
  const [games, setGames] = useState<Game[]>([]);
  const [selectedGame, setSelectedGame] = useState<Game | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [showGameForm, setShowGameForm] = useState(false);
  const [newGame, setNewGame] = useState({ title: '', description: '', genre: '', multiplayerInfo: '', imageURL: '' });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [gameToDelete, setGameToDelete] = useState<string | null>(null);

  useEffect(() => {
    const q = query(collection(db, 'games'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const gamesData: Game[] = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        gamesData.push({
          id: doc.id,
          title: data.title,
          description: data.description,
          genre: data.genre,
          multiplayerInfo: data.multiplayerInfo,
          imageURL: data.imageURL,
        });
      });
      setGames(gamesData);
    });

    return () => unsubscribe();
  }, []);

  const handleEditGame = async () => {
    if (!selectedGame) return;

    try {
      await updateDoc(doc(db, 'games', selectedGame.id), newGame);
      setEditMode(false);
      setSelectedGame(null);
    } catch (error) {
      console.error("Failed to update game: ", error);
    }
  };

  const handleDeleteGame = async () => {
    if (gameToDelete) {
      try {
        await deleteDoc(doc(db, 'games', gameToDelete));
        setDeleteDialogOpen(false);
        setGameToDelete(null);
      } catch (error) {
        console.error("Failed to delete game: ", error);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewGame({ ...newGame, [e.target.name]: e.target.value });
  };

  const handleOpenDeleteDialog = (id: string) => {
    setGameToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setGameToDelete(null);
  };

  return (
    <Container>
      <Button onClick={() => setShowGameForm(true)} className="add-game-button">Add Game</Button>
      {games.map(game => (
        <Card key={game.id} className="game-card">
          <CardMedia
            component="img"
            height="140"
            image={game.imageURL}
            alt={game.title}
            className="game-card-media"
          />
          <CardContent className="game-card-content">
            <Typography variant="h5" className="game-title">{game.title}</Typography>
            <Typography className="game-description">{game.description}</Typography>
            <Typography className="game-genre">Genre: {game.genre}</Typography>
            <Typography className="game-multiplayer">Multiplayer Info: {game.multiplayerInfo}</Typography>
            <div className="card-actions">
              <IconButton onClick={() => {
                setSelectedGame(game);
                setNewGame(game);
                setEditMode(true);
              }} className="edit-button">
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleOpenDeleteDialog(game.id)} className="delete-button">
                <DeleteIcon />
              </IconButton>
            </div>
          </CardContent>
        </Card>
      ))}
      <AddGameForm open={showGameForm} handleClose={() => setShowGameForm(false)} />
      <Dialog open={editMode} onClose={() => setEditMode(false)} PaperProps={{ style: { backgroundColor: '#333', color: '#ffffff' } }}>
        <DialogTitle>編集ゲーム</DialogTitle>
        <DialogContent>
          <TextField
            label="タイトル"
            fullWidth
            variant="outlined"
            margin="dense"
            name="title"
            value={newGame.title}
            onChange={handleChange}
            InputLabelProps={{ style: { color: '#ffffff' } }}
            inputProps={{ style: { color: '#ffffff' } }}
          />
          <TextField
            label="概要"
            fullWidth
            variant="outlined"
            margin="dense"
            name="description"
            value={newGame.description}
            onChange={handleChange}
            InputLabelProps={{ style: { color: '#ffffff' } }}
            inputProps={{ style: { color: '#ffffff' } }}
          />
          <TextField
            label="ジャンル"
            fullWidth
            variant="outlined"
            margin="dense"
            name="genre"
            value={newGame.genre}
            onChange={handleChange}
            InputLabelProps={{ style: { color: '#ffffff' } }}
            inputProps={{ style: { color: '#ffffff' } }}
          />
          <TextField
            label="マルチプレイヤー人数"
            fullWidth
            variant="outlined"
            margin="dense"
            name="multiplayerInfo"
            value={newGame.multiplayerInfo}
            onChange={handleChange}
            InputLabelProps={{ style: { color: '#ffffff' } }}
            inputProps={{ style: { color: '#ffffff' } }}
          />
          <TextField
            label="画像URL"
            fullWidth
            variant="outlined"
            margin="dense"
            name="imageURL"
            value={newGame.imageURL}
            onChange={handleChange}
            InputLabelProps={{ style: { color: '#ffffff' } }}
            inputProps={{ style: { color: '#ffffff' } }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditMode(false)} style={{ color: '#ffffff' }}>キャンセル</Button>
          <Button onClick={handleEditGame} style={{ color: '#ffffff' }}>更新</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>ゲームの削除</DialogTitle>
        <DialogContent>
          <Typography>本当にこのゲームを削除しますか？</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>キャンセル</Button>
          <Button onClick={handleDeleteGame} color="secondary">削除</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default GameList;
