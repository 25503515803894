// AdminPage.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase';
import { doc, getDocs, collection, setDoc, deleteDoc, getDoc } from 'firebase/firestore';
import { Container } from '@mui/material';
import ApprovedUsersList from '../functions/ApprovedUsersList';
import AccessRequestsList from '../functions/AccessRequestsList';
import '../styles/AdminPage.css';

interface UserDetail {
  id: string;
  name: string;
}

const AdminPage = () => {
  const [approvedUsers, setApprovedUsers] = useState<UserDetail[]>([]);
  const [requests, setRequests] = useState<UserDetail[]>([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdmin = async () => {
      const user = auth.currentUser;
      if (user) {
        const roleDoc = await getDoc(doc(db, "roles", user.uid));
        if (roleDoc.exists() && roleDoc.data().role === 'admin') {
          setIsAdmin(true);
        } else {
          navigate('/');
        }
      }
    };

    checkAdmin();
  }, [navigate]);

  const fetchUsers = async () => {
    try {
      const approvedUsersRef = collection(db, "approvedUsers");
      const approvedUsersSnapshot = await getDocs(approvedUsersRef);
      setApprovedUsers(approvedUsersSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name })));

      const requestsRef = collection(db, "accessRequests");
      const requestsSnapshot = await getDocs(requestsRef);
      setRequests(requestsSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name })));
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    if (isAdmin) {
      fetchUsers();
    }
  }, [isAdmin]);

  const approveUser = async (user: UserDetail) => {
    await setDoc(doc(db, "approvedUsers", user.id), { name: user.name });
    await deleteDoc(doc(db, "accessRequests", user.id));
    fetchUsers();
  };

  const rejectUser = async (user: UserDetail) => {
    await deleteDoc(doc(db, "accessRequests", user.id));
    fetchUsers();
  };

  if (!isAdmin) {
    return null;
  }

  return (
    <Container maxWidth="lg" className="admin-container">
      <ApprovedUsersList approvedUsers={approvedUsers} />
      <AccessRequestsList requests={requests} approveUser={approveUser} rejectUser={rejectUser} />
    </Container>
  );
};

export default AdminPage;
