// EventForm.tsx
import React, { useState, useEffect } from 'react';
import { TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { addEvent } from '../services/firebaseService';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import '../styles/EventForm.css';

interface EventFormProps {
  open: boolean;
  handleClose: () => void;
}

const EventForm: React.FC<EventFormProps> = ({ open, handleClose }) => {
  const [title, setTitle] = useState('');
  const [start, setStart] = useState('');
  const [description, setDescription] = useState('');
  const [proposer, setProposer] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        setProposer(user.displayName || '');
      }
    });
    return () => unsubscribe();
  }, []);

  const handleSubmit = async () => {
    try {
      if (title && start && description && proposer) {
        await addEvent({ title, start: new Date(start), description, status: 'Pending', proposer });
        handleClose();
      } else {
        alert('All fields are required.');
      }
    } catch (error) {
      console.error("Failed to add event: ", error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} className="event-form-dialog">
      <DialogTitle>新しいイベント</DialogTitle>
      <DialogContent>
        <TextField
          label="タイトル"
          fullWidth
          variant="outlined"
          margin="dense"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="event-form-textfield"
        />
        <TextField
          label="開始日時"
          fullWidth
          variant="outlined"
          margin="dense"
          type="datetime-local"
          value={start}
          onChange={(e) => setStart(e.target.value)}
          className="event-form-textfield"
        />
        <TextField
          label="コメント"
          fullWidth
          variant="outlined"
          margin="dense"
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="event-form-textfield"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ color: '#ffffff' }}>キャンセル</Button>
        <Button onClick={handleSubmit} style={{ color: '#ffffff' }}>追加</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventForm;
