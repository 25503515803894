// Auth.tsx
import React, { Fragment } from "react";
import { auth, signInWithPopup, GoogleAuthProvider } from "../firebase";
import { User } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Box, Button, Container, Grid } from '@mui/material';
import { db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import '../styles/Auth.css';

const Auth: React.FC = () => {
  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user: User = result.user;

      const userDocRef = doc(db, "approvedUsers", user.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        navigate("/home");
      } else {
        const requestDocRef = doc(db, "accessRequests", user.uid);
        await setDoc(requestDocRef, {
          uid: user.uid,
          name: user.displayName,
          email: user.email
        });
        alert("Your access request has been submitted. Please wait for admin approval.");
        await auth.signOut();
      }
    } catch (error: any) {
      console.error("Login error", error);
      alert(error.message);
    }
  };

  return (
    <Fragment>
      <Container>
        <Grid container justifyContent="center" className="auth-container">
          <Grid item md={4}>
            <Box component="form" className="auth-form">
              <Button fullWidth variant="contained" onClick={signInWithGoogle} className="auth-button">
                Googleアカウントでサインイン
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default Auth;
