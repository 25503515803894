// AttendanceCheck.tsx
import React, { useEffect, useState } from 'react';
import { Button, Typography, List, ListItem, ListItemText } from '@mui/material';
import { db } from '../firebase';
import { updateEventStatus } from '../services/firebaseService';
import { collection, query, where, getDocs } from 'firebase/firestore';
import '../styles/AttendanceCheck.css';

interface AttendanceCheckProps {
  event: { id: string; title: string; description: string; status: string };
}

const AttendanceCheck: React.FC<AttendanceCheckProps> = ({ event }) => {
  const [responses, setResponses] = useState<any[]>([]);

  useEffect(() => {
    loadResponses();
  }, [event.id]);

  const updateStatus = async (newStatus: string) => {
    if (!event.id || !newStatus) {
        console.error("Invalid or missing event data");
        return;
    }
    try {
        await updateEventStatus(event.id, newStatus);
    } catch (error) {
        console.error("Failed to update event status: ", error);
    }
  };

  const loadResponses = async () => {
    const q = query(collection(db, "responses"), where("eventId", "==", event.id));
    const querySnapshot = await getDocs(q);
    const loadedResponses: any[] = [];
    querySnapshot.forEach((doc) => {
      loadedResponses.push(doc.data());
    });
    setResponses(loadedResponses);
  };

  return (
    <>
      <Typography variant="body1" className="attendance-check-description">{event.description}</Typography>
      <Typography variant="body2" color="textSecondary" className="attendance-check-status">
        状態：{event.status}
      </Typography>
      <Button onClick={() => updateStatus('Approved')} className="attendance-check-button">OK</Button>
      <Button onClick={() => updateStatus('Rejected')} className="attendance-check-button">NG</Button>
      <List className="attendance-check-list">
        {responses.map((response, index) => (
          <ListItem key={index}>
            <ListItemText primary={response.name} secondary={`コメント: ${response.comment}`} />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default AttendanceCheck;
