// firebaseService.ts
import { db } from '../firebase';
import { doc, setDoc, collection, addDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

async function addEvent(event: { title: string; start: Date; description: string; status: string; proposer: string }) {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not authenticated');
  }

  const eventRef = await addDoc(collection(db, "events"), {
    ...event,
    start: Timestamp.fromDate(event.start),
    end: Timestamp.fromDate(event.start), // 必要に応じて修正
    date: Timestamp.fromDate(new Date())
  });
  console.log("Document written with ID: ", eventRef.id);
  return eventRef.id; // 新しく追加したイベントのIDを返す
}

async function updateEventStatus(eventId: string, newStatus: string) {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not authenticated');
  }

  const eventRef = doc(db, "events", eventId);
  try {
    await updateDoc(eventRef, { status: newStatus });
    console.log("Event status updated to:", newStatus);
  } catch (e) {
    console.error("Error updating document: ", e);
  }
}

export { addEvent, updateEventStatus };
