// App.tsx
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Auth from '../functions/Auth';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import Header from '../functions/Header';
import Footer from '../functions/Footer';
import HomePage from './HomePage';
import GameCalendar from './GameCalendar';
import GameList from './GameList';
import MyPage from '../functions/MyPage';
import AdminPage from './AdminPage';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setIsAuthenticated(!!user);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>Loading...</div>;
  }

  return (
    <Router>
      <Header />
      <main>
        <Routes>
          <Route path="/auth" element={<Auth />} />
          <Route path="/" element={isAuthenticated ? <HomePage /> : <Navigate to="/auth" />} />
          <Route path="/home" element={isAuthenticated ? <HomePage /> : <Navigate to="/auth" />} />
          <Route path="/calendar" element={isAuthenticated ? <GameCalendar /> : <Navigate to="/auth" />} />
          <Route path="/games" element={isAuthenticated ? <GameList /> : <Navigate to="/auth" />} />
          <Route path="/mypage" element={isAuthenticated ? <MyPage /> : <Navigate to="/auth" />} />
          <Route path="/admin" element={isAuthenticated ? <AdminPage /> : <Navigate to="/auth" />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
