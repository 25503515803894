// HomePage.tsx
import { Box, Typography, Button, Container } from '@mui/material';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import '../styles/HomePage.css';

const HomePage = () => {
  return (
    <Container maxWidth="md" className="homepage-container">
      <motion.div
        initial={{ opacity: 0, translateY: 50 }}
        animate={{ opacity: 1, translateY: 0 }}
        transition={{ duration: 0.5 }}
        className="hero"
      >
        <Typography variant="h2" gutterBottom className="hero-title">三十路社会主義共和国</Typography>
        <Typography variant="body1" gutterBottom className="hero-subtitle">ここはゲーム好きの三十路が統治する世界。</Typography>
      </motion.div>
      <nav className="nav-buttons">
        {['Home', 'Calendar', 'Games', 'MyPage'].map((text) => (
          <motion.div
            key={text}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="nav-button-wrapper"
          >
            <Button component={Link} to={`/${text.toLowerCase()}`} className="custom-button">
              {text}
            </Button>
          </motion.div>
        ))}
      </nav>
    </Container>
  );
};

export default HomePage;
