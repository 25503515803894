// MyPage.tsx
import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Box, styled } from '@mui/material';
import { db, auth, GoogleAuthProvider, signInWithPopup, signOut } from '../firebase';
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import '../styles/MyPage.css';

const StyledContainer = styled(Container)(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(4),
  padding: theme.spacing(3),
  backgroundColor: '#1c1c1c',
  color: '#ffffff',
  borderRadius: '8px',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
}));

const MyPage: React.FC = () => {
  const [user, setUser] = useState<any>(null);
  const [name, setName] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setName(data.name);
        } else {
          console.log("No such document!");
        }
      } else {
        console.log("No user is signed in.");
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = async () => {
    try {
      if (user) {
        const docRef = doc(db, "users", user.uid);
        await updateDoc(docRef, {
          name
        });
        alert("Profile updated successfully!");
      }
    } catch (error) {
      console.error("Failed to update profile: ", error);
      alert("Failed to update profile. Please check your permissions.");
    }
  };

  const handleGoogleAccountChange = async () => {
    try {
      await signOut(auth);
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const newUser = result.user;

      const approvedUserRef = doc(db, "approvedUsers", newUser.uid);
      const approvedUserDoc = await getDoc(approvedUserRef);

      if (approvedUserDoc.exists()) {
        const userRef = doc(db, "users", newUser.uid);
        await setDoc(userRef, {
          name
        });
        alert("Google account has been changed successfully!");
      } else {
        alert("New Google account is not approved. Please contact the administrator.");
        await signOut(auth);
      }
    } catch (error) {
      console.error("Failed to change Google account: ", error);
      alert("Failed to change Google account. Please check your permissions.");
    }
  };

  return (
    <StyledContainer maxWidth="sm" className="my-page-container">
      <Typography variant="h4" gutterBottom>My Page</Typography>
      <TextField
        label="名前"
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        margin="normal"
        className="my-page-textfield"
      />
      <Box mt={2} className="my-page-button">
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          更新
        </Button>
      </Box>
      <Box mt={2} className="my-page-google-button">
        <Button variant="outlined" color="secondary" onClick={handleGoogleAccountChange}>
          Googleアカウントを変更
        </Button>
      </Box>
    </StyledContainer>
  );
};

export default MyPage;
