// AddGameForm.tsx
import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import '../styles/AddGameForm.css';

interface AddGameFormProps {
  open: boolean;
  handleClose: () => void;
}

const AddGameForm: React.FC<AddGameFormProps> = ({ open, handleClose }) => {
  const [newGame, setNewGame] = useState({ title: '', description: '', genre: '', multiplayerInfo: '', imageURL: '' });
  const [errors, setErrors] = useState({ title: false });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewGame({ ...newGame, [e.target.name]: e.target.value });
  };

  const handleAddGame = async () => {
    const newErrors = {
      title: !newGame.title,
    };

    setErrors(newErrors);

    if (newErrors.title) {
      console.error("Title must be filled");
      return;
    }

    try {
      await addDoc(collection(db, 'games'), newGame);
      handleClose();
      setNewGame({ title: '', description: '', genre: '', multiplayerInfo: '', imageURL: '' });
    } catch (error) {
      console.error("Failed to add game: ", error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} className="add-game-form-dialog">
      <DialogTitle>新しいゲーム</DialogTitle>
      <DialogContent>
        <TextField
          label="タイトル"
          fullWidth
          variant="outlined"
          margin="dense"
          name="title"
          value={newGame.title}
          onChange={handleChange}
          error={errors.title}
          helperText={errors.title ? 'タイトルを入力してください' : ''}
          className="add-game-form-textfield"
        />
        <TextField
          label="概要"
          fullWidth
          variant="outlined"
          margin="dense"
          name="description"
          value={newGame.description}
          onChange={handleChange}
          className="add-game-form-textfield"
        />
        <TextField
          label="ジャンル"
          fullWidth
          variant="outlined"
          margin="dense"
          name="genre"
          value={newGame.genre}
          onChange={handleChange}
          className="add-game-form-textfield"
        />
        <TextField
          label="マルチプレイヤー人数"
          fullWidth
          variant="outlined"
          margin="dense"
          name="multiplayerInfo"
          value={newGame.multiplayerInfo}
          onChange={handleChange}
          className="add-game-form-textfield"
        />
        <TextField
          label="画像URL"
          fullWidth
          variant="outlined"
          margin="dense"
          name="imageURL"
          value={newGame.imageURL}
          onChange={handleChange}
          className="add-game-form-textfield"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ color: '#ffffff' }}>キャンセル</Button>
        <Button onClick={handleAddGame} style={{ color: '#ffffff' }}>追加</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddGameForm;
