// Footer.tsx
import { Box, Typography } from '@mui/material';
import '../styles/Footer.css';

const Footer = () => {
  return (
    <Box className="footer-container">
      <Typography variant="body2">
        © 2024 30s Socialist Republic
      </Typography>
    </Box>
  );
};

export default Footer;
