// Header.tsx
import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, Box } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import MenuIcon from '@mui/icons-material/Menu';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import { Link } from 'react-router-dom';
import '../styles/Header.css';

const drawerVariants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: "-100%" }
};

const Header = () => {
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <AnimatePresence>
      {mobileOpen && (
        <motion.div
          variants={drawerVariants}
          initial="closed"
          animate="open"
          exit="closed"
          transition={{ type: "spring", stiffness: 260, damping: 20 }}
        >
          <Box sx={{ textAlign: 'right' }}>
            <List>
              <ListItem button component={Link} to="/home">
                <ListItemText primary="Home" primaryTypographyProps={{ style: { color: 'white' } }} />
              </ListItem>
              <ListItem button component={Link} to="/calendar">
                <ListItemText primary="Calendar" primaryTypographyProps={{ style: { color: 'white' } }} />
              </ListItem>
              <ListItem button component={Link} to="/games">
                <ListItemText primary="Games" primaryTypographyProps={{ style: { color: 'white' } }} />
              </ListItem>
              <ListItem button component={Link} to="/mypage">
                <ListItemText primary="MyPage" primaryTypographyProps={{ style: { color: 'white' } }} />
              </ListItem>
              <ListItem button component={Link} to="/admin">
                <ListItemText primary="Admin" primaryTypographyProps={{ style: { color: 'white' } }} />
              </ListItem>
            </List>
          </Box>
        </motion.div>
      )}
    </AnimatePresence>
  );

  return (
    <AppBar position="static" sx={{ backgroundColor: '#333', boxShadow: 'none' }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <SportsEsportsIcon sx={{ color: 'white', marginRight: '8px' }} />
          <Typography variant="h6" sx={{ color: 'white', fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' } }}>
            30SR
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
          <Button component={Link} to="/home" sx={{ color: 'white' }}>
            Home
          </Button>
          <Button component={Link} to="/calendar" sx={{ color: 'white' }}>
            Calendar
          </Button>
          <Button component={Link} to="/games" sx={{ color: 'white' }}>
            Games
          </Button>
          <Button component={Link} to="/mypage" sx={{ color: 'white' }}>
            MyPage
          </Button>
          <Button component={Link} to="/admin" sx={{ color: 'white' }}>
            Admin
          </Button>
        </Box>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerToggle}
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240, backgroundColor: '#333', color: '#ffffff' },
          display: { xs: 'block', md: 'none' }
        }}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
};

export default Header;
