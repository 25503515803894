// AccessRequestsList.tsx
import React from 'react';
import { List, ListItem, ListItemText, Avatar, Paper, Typography, Divider, Box, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import '../styles/AccessRequestsList.css';

interface UserDetail {
  id: string;
  name: string;
}

interface AccessRequestsListProps {
  requests: UserDetail[];
  approveUser: (user: UserDetail) => Promise<void>;
  rejectUser: (user: UserDetail) => Promise<void>;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(3),
  backgroundColor: '#1c1c1c',
  color: '#ffffff',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
}));

const AccessRequestsList: React.FC<AccessRequestsListProps> = ({ requests, approveUser, rejectUser }) => {
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  const handleUserIdClick = (userId: string) => {
    setSelectedUserId(selectedUserId === userId ? null : userId);
  };

  return (
    <StyledPaper>
      <Typography variant="h4" component="h2" gutterBottom>
        アクセスリクエスト一覧
      </Typography>
      <Divider />
      <List>
        {requests.map(user => (
          <ListItem key={user.id} className="user-list-item">
            <Avatar className="user-avatar">{user.name.charAt(0)}</Avatar>
            <ListItemText
              primary={<span className="user-name">{user.name}</span>}
              secondary={
                selectedUserId === user.id ? (
                  <span className="user-id">{user.id}</span>
                ) : (
                  <span className="user-id-hidden" onClick={() => handleUserIdClick(user.id)}>IDを表示</span>
                )
              }
            />
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={() => approveUser(user)} className="approve-button">
                <CheckIcon />
              </IconButton>
              <IconButton onClick={() => rejectUser(user)} className="reject-button">
                <CloseIcon />
              </IconButton>
            </Box>
          </ListItem>
        ))}
      </List>
    </StyledPaper>
  );
};

export default AccessRequestsList;
