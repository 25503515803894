// GameCalendar.tsx
import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { db } from '../firebase';
import { collection, query, onSnapshot } from 'firebase/firestore';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Container, Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import EventForm from '../functions/EventForm';
import AttendanceCheck from '../functions/AttendanceCheck';
import { Timestamp } from 'firebase/firestore';
import { motion } from 'framer-motion';
import '../styles/GameCalendar.css';

interface Event {
  id: string;
  title: string;
  description: string;
  status: string;
  start: Date;
  end: Date;
}

const localizer = momentLocalizer(moment);

const GameCalendar = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [showEventForm, setShowEventForm] = useState<boolean>(false);

  useEffect(() => {
    const q = query(collection(db, 'events'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const loadedEvents: Event[] = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        try {
          const start = data.start instanceof Timestamp ? data.start.toDate() : new Date(data.start);
          const end = data.end instanceof Timestamp ? data.end.toDate() : new Date(data.end);

          loadedEvents.push({
            id: doc.id,
            title: data.title,
            description: data.description,
            status: data.status,
            start: start,
            end: end,
          });
        } catch (error) {
          console.error(`Invalid event data: ${doc.id}`, data, error);
        }
      });
      setEvents(loadedEvents);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleEventClick = (event: Event) => {
    setSelectedEvent(event);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShowEventForm(false);
  };

  const eventStyleGetter = (event: Event) => {
    let backgroundColor = '#333';
    if (event.status === 'Approved') {
      backgroundColor = '#4caf50'; // Green for approved
    } else if (event.status === 'Rejected') {
      backgroundColor = '#f44336'; // Red for rejected
    } else if (event.status === 'Pending') {
      backgroundColor = '#ff9800'; // Orange for pending
    }
    const style = {
      backgroundColor,
      borderRadius: '5px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block',
      padding: '5px',
    };
    return {
      style: style,
    };
  };

  const messages = {
    allDay: '終日',
    previous: '前',
    next: '次',
    today: '今日',
    month: '月',
    week: '週',
    day: '日',
    agenda: '予定',
    date: '日付',
    time: '時間',
    event: 'イベント',
    noEventsInRange: '表示するイベントがありません。',
    showMore: (total: number) => `+ ${total} 件`,
  };

  return (
    <Container maxWidth="lg" className="game-calendar-container">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Button onClick={() => setShowEventForm(true)} variant="contained" className="custom-button">
          イベントを追加
        </Button>
        {showEventForm && <EventForm open={showEventForm} handleClose={handleClose} />}
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: '70vh', margin: '50px 0', background: '#121212', color: '#E0E0E0', overflow: 'hidden' }}
          onSelectEvent={handleEventClick}
          eventPropGetter={eventStyleGetter}
          messages={messages}
        />
        {selectedEvent && (
          <Dialog open={open} onClose={handleClose} PaperProps={{ style: { backgroundColor: '#333', color: '#ffffff' } }}>
            <DialogTitle>{selectedEvent.title}</DialogTitle>
            <DialogContent>
              <AttendanceCheck event={selectedEvent} />
            </DialogContent>
          </Dialog>
        )}
      </motion.div>
    </Container>
  );
};

export default GameCalendar;
